import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import { P } from '../../../../style/Typography.style'

import Table from '../../../../components/Table'
import Flex from '../../../../components/layouts/Flex'
import { FilterCheckBox } from '../../../../components/dashboard/FilterInput'
import TableAction from './TableAction'

import axiosInstance from '../../../../services/config/AxiosIntance'

import useBulkActions from '../hooks/useBulkActions'

import {
  setApplicantListUrl,
  setUpdatedStage,
  setInterviews,
} from '../../../../store/actions/recruitersActions'

const ClientInterview = ({ onRowClick }) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { filters, updatedStage, refreshList, changedInterviews, interviews } =
    useSelector((state) => state.requisitions)

  const {
    getCheckedResult,
    handleCheckColumn,
    handleCheckRow,
    selectedAll,
    checkedItems,
  } = useBulkActions()

  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)
  const [rowCount, setRowCount] = useState(0)

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const userId = JSON.parse(currentUser)?.id
  const recruiterAssistant = !!JSON.parse(currentUser)?.recruiter_assistant
  const interviewerId = JSON.parse(currentUser)?.interviewerId

  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails

  const stage = 'client_interview'

  let url = `/interviews?stage=${stage}&searchTerm=${filters.search}&job_title=${filters.searchRole}&vacancy=${filters.vacancy}&startDate=${filters.startDate}&endDate=${filters.endDate}&sort=${filters.sort}&readBy=${userId}`

  if (!recruiterAssistant && role === 'recruiter') {
    url += `&recruiterId=${userId}`
  } else if (role === 'interviewer') {
    url += `&interviewerId=${interviewerId}`
  } else if (role === 'poc') {
    url += `&companyDetails=${JSON.stringify(companyDetails)}`
  }

  const fetchData = useCallback(async (url) => {
    setLoading(true)
    try {
      const result = await axiosInstance.get(url)

      const { data } = result

      dispatch(setApplicantListUrl(url))

      setTotalPage(data?.resp?.pagination?.pageCount || 1)
      setCurrentPage(data?.resp?.pagination?.page || 1)
      setPageSize(data?.resp?.pagination?.pageSize || 10)
      setRowCount(data?.resp?.pagination?.rowCount || 0)

      dispatch(setInterviews(data?.resp?.interviews || []))
      setLoading(false)
    } catch (err) {
      addToast(err?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
      })
      setLoading(false)
    }
    dispatch(setUpdatedStage(''))
  }, [])

  useEffect(() => {
    if (interviews.length && changedInterviews.length) {
      const updatedInterviews = interviews.map((item) => {
        const replacement = changedInterviews.find((t) => t.id === item.id)
        return replacement || item
      })

      dispatch(setInterviews(updatedInterviews))
    }
  }, [changedInterviews])

  useEffect(() => {
    fetchData(url)
  }, [filters])

  useEffect(() => {
    if (updatedStage === 'client_interview') fetchData(url)
  }, [updatedStage])

  useEffect(() => {
    if (updatedStage === 'client_interview' && refreshList === updatedStage) {
      fetchData(url)
    }
  }, [refreshList])

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <FilterCheckBox
            checked={selectedAll}
            mr="0rem"
            size="small"
            justifyContent="center"
            onClick={(e) => handleCheckColumn(e, rowCount)}
          />
        ),
        width: 100,
        accessor: 'checkable',
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <FilterCheckBox
              checked={getCheckedResult(value)}
              mr="0rem"
              size="small"
              justifyContent="center"
              onClick={(e) => handleCheckRow(e, value)}
            />
          )
        },
      },
      {
        Header: 'Action',
        accessor: 'actions',
        disableSortBy: true,
        Cell: ({ value }) => <TableAction value={value} />,
      },
      {
        Header: 'Applicant',
        accessor: 'applicantName',
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: 10000,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Client',
        accessor: 'client',
      },
      {
        Header: 'Contact No.',
        accessor: 'contact',
      },
      {
        Header: 'Recruiter',
        accessor: 'recruiter',
      },
      {
        Header: 'Interviewer',
        accessor: 'interviewer',
      },
      {
        Header: 'Channel',
        accessor: 'channel',
      },
      {
        Header: 'Asking Salary',
        accessor: 'asking_salary',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
        Cell: ({ value }) => (value ? moment(value).format('DD/MM/YYYY') : ''),
        sortType: 'datetime',
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdated',
        Cell: ({ value }) => (value ? moment(value).format('DD/MM/YYYY') : ''),
        sortType: 'datetime',
      },
    ],
    [selectedAll, checkedItems, rowCount]
  )

  const tableData = interviews.map(
    (item) =>
      ({
        checkable: item.id,
        readApplicantId: item.readApplicantId,
        actions: item,
        applicantName: item.applicantName || '',
        role: item.role || '',
        email: item.applicantEmail || '',
        client: item.client || '',
        contact: item.mobileNumber || '',
        recruiter: item.recruiterName || '',
        interviewer: item.interviewer || item.interviewerRecruiter || '',
        schedule:
          moment(item.interview_date).format('DD/MM/YYYY') +
            ' • ' +
            moment(item.interview_time).format('hh:mm a') || '',
        addedBy: item.added_by || '',
        channel: item.channel || '',
        askingSalary: item.askingSalary || '',
        dateCreated: item.created_at ? new Date(item.created_at) : null,
        lastUpdated: item.updated_at ? new Date(item.updated_at) : null,
      } || '')
  )

  return (
    <StyledClientInterview>
      {interviews?.length > 0 ? (
        <Table
          data={tableData}
          columns={columns}
          loading={loading}
          totalPage={totalPage}
          currentPage={currentPage}
          pageSize={pageSize}
          rowCount={rowCount}
          fetchData={fetchData}
          setNewPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          url={`${url}&pageNumber=${currentPage}&pageSize=${pageSize}`}
          noData="Client Interview is empty"
          height="calc(100vh - 20.3rem)"
          onClick={onRowClick}
        />
      ) : (
        <Flex
          height="calc(100vh - 15rem)"
          bg="#fff"
          justify="center"
          items="center"
        >
          <P>Client Interview is empty</P>
        </Flex>
      )}
    </StyledClientInterview>
  )
}

export default ClientInterview

const StyledClientInterview = styled.div`
  width: 100%;
  height: 100%;
`
